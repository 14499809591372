import { Headphones, LocationOn } from '@mui/icons-material'
import React from 'react'

const PartyCard = (props) => {
   const {eventName, date, imgUrl, artists, location, hour} = props
    return ( 
        <div className='flex flex-col w-72 border border-gray-900 rounded-3xl overflow-hidden'>
            <div className='h-64 bg-cover bg-center' style={{backgroundImage: `url(${imgUrl})`}}></div>
            <div className='relative px-5 py-5'>
                <div className='absolute inset-0 bg-cover bg-center blur-md brightness-40' style={{backgroundImage: `url(${imgUrl})`}}/>
                <div className='relative flex flex-col gap-2'>
                    <div className='relative flex flex-col gap-0'>
                        {/* {eventName}, {date}, {imgUrl}, {artists}, {location} */}
                        <h3 className='text-xl font-extrabold'>{eventName}</h3>
                        <h4 className='text-xs'>{date},   {hour}</h4>
                    </div>
                    <div className='flex items-center gap-1'>
                        <LocationOn className='text-gray-500' fontSize='xs'/>
                        <h4 className='text-xs mt-0.5'>{location}</h4>
                    </div>
                    <div className='flex items-center gap-1'>
                        <Headphones className='text-gray-500' fontSize='xs'/>
                        <h4 className='text-xs mt-0.5'>{artists}</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartyCard
