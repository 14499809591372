import PartyCard from "../../../components/PartyCard"

const Events = () => {
    return (
        <div id="events">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <h2 className="text-3xl">Past Events</h2>
              <div class="border-t border-gray-900"></div>
            </div>
            <div className="flex gap-5 flex-wrap items-center justify-center">
              <PartyCard eventName="STASI SANLIN" date="18.01.2025" imgUrl="stasi.png" location="Royal Hall" artists="Stasi Sanlin" hour="22:30"/>
              <PartyCard eventName="MYSTERE" date="07.12.2024" imgUrl="mystere.jpeg" location="Tuya" artists="Monkey Safari, Ozlemek" hour="23:00"/>
              <PartyCard eventName="AMURG 2.0" date="02.11.2024" imgUrl="amurg.png" location="Nuba Chalet" artists="Roy Rosenfeld, Fiorella, Ozlemek" hour="19:00"/>
              <PartyCard eventName="GUY GERBER" date="12.10.2024" imgUrl="gerber.jpeg" location="Dracula's Castle" artists="Guy Gerber" hour="20:00"/>
              <PartyCard eventName="LAZARE" date="08.09.2024" imgUrl="lazare.jpeg" location="HILLHOUSE•CLUJ" artists="Lazare, Ozlemek" hour="22:00"/>
              <PartyCard eventName="MONT ROUGE" date="08.09.2024" imgUrl="rouge.jpeg" location="TUYA" artists="Mont Rouge" hour="22:00"/>
              <PartyCard eventName="EUPHORIA" date="15.06.2024" imgUrl="euphoria.jpeg" location="TUYA" artists="Eva Kim, Ozlemek" hour="21:00"/>
              <PartyCard eventName="RAYANA" date="19.04.2024" imgUrl="rayana.jpg" location="NAYAM" artists="Fiorella, Big Pizza, Ozlemek" hour="22:00"/>
              <PartyCard eventName="SECRETÉ" date="05.04.2024" imgUrl="secret.jpg" location="Nuba White" artists="Arymé, Big Pizza" hour="22:00"/>
              <PartyCard eventName="ZENITH" date="23.02.2024" imgUrl="zenith.png" location="Hiro Bay" artists="Maxi Meraki, Ozlemek" hour="21:00"/>
              <PartyCard eventName="LUMIÈRE" date="26.01.2024" imgUrl="lumiere.jpeg" location="Nuba" artists="Mont Rouge, Ozlemek" hour="21:00"/>
            </div>
          </div>
    
        </div>
    )
}

export default Events
